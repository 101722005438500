import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'
import { faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons'

function properCase(str) {
  return str
    .split('-')
    .map((s) => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

const Sitemap = ({ data, location }) => {
  const news = data.news.edges
  const heroImage = data.heroImage
  const legal = data.legal.edges
  const products = data.products.edges

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title="USR - Sitemap"
          description="Use this sitemap to find the content you're looking for and then navigate your way around the USR website."
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src }
        />
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="USR sitemap"
          subtitle=""
          type="sitemap"
        />
      </div>
      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="8">
              <MDBRow>
                <MDBCol md="6" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mb-5 mt-lg-5"> Home 
                    <Link to="/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> 
                  </h2>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Company 
                    <Link to="/about/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>
                  <p className="font-w-400 text-medium"> <Link to="/about/" className="effect-noline"> About us </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/contact/" className="effect-noline"> Contact us </Link> </p>
                  <p className="font-w-400 text-medium mb-3"> <Link to="/resources/" className="effect-noline"> Resources </Link> </p>
                  <ul className="fa-ul text-card-small">
                    <li> 
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span> 
                      <Link to="/resources/videos/" className="effect-noline"> Videos </Link> 
                    </li>
                    <li> 
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/resources/product-datasheets/" className="effect-noline" > Product datasheets </Link>
                    </li>
                    <li> 
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/resources/case-studies/" className="effect-noline" > Case studies </Link>
                    </li>
                    <li> 
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/resources/white-papers/" className="effect-noline" > White papers </Link>
                    </li>
                    <li> 
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/resources/backgrounder/" className="effect-noline" > Backgrounder </Link>
                    </li>
                    <li> 
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/resources/fact-sheet/" className="effect-noline" > Fact sheet </Link>
                    </li>                                        
                  </ul>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Newsroom
                    <Link to="/news/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>
                  {news.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}>
                      <Link to={`/news/${node.slug + `/`}`} className="effect-noline" > {properCase(node.title)} </Link>
                    </p>
                  ))}
                  <ul className="fa-ul text-medium ml-4">
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/news/" className="effect-noline"> More news </Link>
                    </li>
                  </ul>               

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Privacy &amp; legal </h2>
                  {legal.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}>
                      <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link>
                    </p>
                  ))}
                </MDBCol>

                <MDBCol md="6" className="mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Education 
                    <Link to="/education/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>
                  <p className="font-w-400 text-medium">
                    <Link to="/education/courier-business-products/" className="effect-noline" > Courier business products </Link>
                  </p>
                  <ul className="fa-ul text-card-small">
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/courier-why-buy/" className="effect-noline" > Why buy Courier? </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/courier-out-band-connections/" className="effect-noline" > Out-of-Band connections </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/courier-modem-point-sale/" className="effect-noline" > Modem Point of Sale </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/courier-modem-technology/" className="effect-noline" > Modem technology </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/courier-global-kits-lite/" className="effect-noline" > Courier Lite Global Kits </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/courier-global-kits/" className="effect-noline" > Courier (3453C) Global Kits </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/courier-support/" className="effect-noline" > Courier support </Link>
                    </li>
                  </ul>

                  <p className="font-w-400 text-medium">
                      <Link to="/education/dialup-modems/" className="effect-noline" > All about dial-up </Link>
                  </p>
                  <ul className="fa-ul text-card-small">
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-history-usr-modems/" className="effect-noline" > History of USR modems </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-modems-101-basics/" className="effect-noline" > Modems 101: The basics </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-modem-speeds/" className="effect-noline" > Modem speeds </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-v92-detail/" className="effect-noline" > V.92 in detail </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-modem-types/" className="effect-noline" > What are the different types of modems? </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-available-modems/" className="effect-noline" > What kinds of modems are available? </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-getting-online-dial-modems/" className="effect-noline" > Getting online with dial-up modems </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/education/dialup-modem-applications/" className="effect-noline" > Modem applications </Link>
                    </li>                                                                                                        
                  </ul>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Support
                    <Link to="/support/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>
                  <ul className="fa-ul text-card-small">
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/support/product-registration/" className="effect-noline" > Register your product </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/support/support-information/" className="effect-noline" > Technical support form </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/support/sales-support/" className="effect-noline" > Sales support form </Link>
                    </li>
                    <li>
                      <span className="fa-li"> <FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /> </span>
                      <Link to="/support/windows-compatibility/" className="effect-noline" > Windows compatibility </Link>
                    </li>                    
                  </ul>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Global product availability
                    <Link to="/global-product-availability/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>                      

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> International contacts
                    <Link to="/international/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>                                  

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Dealers
                    <Link to="/dealers/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Partners
                    <Link to="/partners/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>             

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Regulatory compliance
                    <Link to="/regulatory-compliance-export/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>

                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> UNICOM Global Divisions 
                    <Link to="/unicom-global/divisions/" className="ml-2 linkedin" > <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>
                  
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Search
                    <Link to="/search/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
                  </h2>                
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol md="4" className="mb-5">
              <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Products
                <Link to="/products/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link>
              </h2>
              <p className="font-w-400 text-medium">
                <Link to="/products/cellular-m2m-modems-gateways/" className="effect-noline" > Cellular M2M modems and gateways </Link>
              </p>
              <p className="font-w-400 text-medium">
                <Link to="/products/56k-dial-up-modems/" className="effect-noline" > 56K dial-up modems </Link>
              </p>
              <p className="font-w-400 text-medium">
                <Link to="/products/networking-taps/" className="effect-noline" > Network TAPs </Link>
              </p>
              <p className="font-w-400 text-medium">
                <Link to="/products/56k-dial-up-modem-comparison/" className="effect-noline" > 56K dial-up modem comparison </Link>
              </p>                          
              {products.map(({ node }, index) => (
                <p className="font-w-400 text-medium" key={index}>
                  <Link to={node.fields.slug} className="effect-noline"> {node.frontmatter.name + ' - ' + properCase(node.frontmatter.title)} </Link>
                </p>
              ))}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default Sitemap

export const query = graphql`
  query SitemapQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    news: allContentfulNews(
      filter: {
        publishTo: { elemMatch: { url: { eq: "https://www.usr.com" } } }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    products: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "products" }, hide: { ne: true } }
      }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            name
          }
          fields {
            slug
          }
        }
      }
    }
    blog: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    about: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "about" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
